import {AxiosResponse} from "axios"

export type AccessDeniedResponse = {
    silent: boolean
    message: string
    defaultMessage: boolean
    payload: Record<string, any> | any[] | null
    __redirectToLogin?: string
    __inertia_visit?: string
}

export const isAccessDeniedEvent = (response: CustomEvent<{ response: AxiosResponse }>): boolean => response?.detail?.response?.status === 403 && response?.detail?.response?.data?.payload !== undefined

export const PermissionTypeEnum = {
    CONSTRUCTION: "PERMISSION_TYPE_CONSTRUCTION",
    CONSTRUCTION_INVITATION: "PERMISSION_TYPE_CONSTRUCTION_INVITATION",
    CONSTRUCTION_SUPPLIER: "PERMISSION_TYPE_CONSTRUCTION_SUPPLIER",
    CONSTRUCTION_PERSONNEL: "PERMISSION_TYPE_CONSTRUCTION_PERSONNEL",
    CONSTRUCTION_ADDITIONAL_RECORD: "PERMISSION_TYPE_CONSTRUCTION_ADDITIONAL_RECORD",
    CONSTRUCTION_MECHANISM: "PERMISSION_TYPE_CONSTRUCTION_MECHANISM",
    CONSTRUCTION_MATERIAL: "PERMISSION_TYPE_CONSTRUCTION_MATERIAL",
    CONSTRUCTION_ASSIGNMENT: "PERMISSION_TYPE_CONSTRUCTION_ASSIGNMENT",

    DIARY: "PERMISSION_TYPE_DIARY",
    DIARY_DOCUMENT: "PERMISSION_TYPE_DIARY_DOCUMENT",
    DIARY_INVITATION: "PERMISSION_TYPE_DIARY_INVITATION",
    DIARY_ASSIGNMENT: "PERMISSION_TYPE_DIARY_ASSIGNMENT",

    DAILY_RECORD: "PERMISSION_TYPE_DAILY_RECORD",
    DAILY_RECORD_WEATHER_STATE: "PERMISSION_TYPE_DAILY_RECORD_WEATHER_STATE",
    DAILY_RECORD_ADDITIONAL_RECORD: "PERMISSION_TYPE_DAILY_RECORD_ADDITIONAL_RECORD",
    DAILY_RECORD_MATERIAL: "PERMISSION_TYPE_DAILY_RECORD_MATERIAL",
    DAILY_RECORD_ON_SITE_MECHANISM: "PERMISSION_TYPE_DAILY_RECORD_ON_SITE_MECHANISM",
    DAILY_RECORD_COMPLETED_JOB: "PERMISSION_TYPE_DAILY_RECORD_COMPLETED_JOB",
    DAILY_RECORD_ATTENDANCE: "PERMISSION_TYPE_DAILY_RECORD_ATTENDANCE",
    DAILY_RECORD_CLOSE_STAGE_ONE: "PERMISSION_TYPE_DAILY_RECORD_CLOSE_STAGE_ONE",
    DAILY_RECORD_CLOSE_STAGE_TWO: "PERMISSION_TYPE_DAILY_RECORD_CLOSE_STAGE_TWO",

    INDIVIDUAL_RECORD: "PERMISSION_TYPE_INDIVIDUAL_RECORD",
    INDIVIDUAL_RECORD_ASSIGNMENT: "PERMISSION_TYPE_INDIVIDUAL_RECORD_ASSIGNMENT",
    INDIVIDUAL_RECORD_INVITATION: "PERMISSION_TYPE_INDIVIDUAL_RECORD_INVITATION",
    INDIVIDUAL_RECORD_SUPPLIER: "PERMISSION_TYPE_INDIVIDUAL_RECORD_SUPPLIER",
    INDIVIDUAL_RECORD_PERSONNEL: "PERMISSION_TYPE_INDIVIDUAL_RECORD_PERSONNEL",
    INDIVIDUAL_RECORD_MECHANISM: "PERMISSION_TYPE_INDIVIDUAL_RECORD_MECHANISM",
} as const

export type PermissionType = typeof PermissionTypeEnum[keyof typeof PermissionTypeEnum]